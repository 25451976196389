import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`RPSL 🚀`}</h1>
    <p><a parentName="p" {...{
        "href": "https://rongyi.io"
      }}>{`荣懿`}</a>{`旗下`}<strong parentName="p">{`推进系统`}</strong>{`实验室`}</p>
    <p>{`Rong's `}<strong parentName="p">{`Propulsion Systems`}</strong>{` Lab`}</p>
    {
      /* ### 星辰大海，我们准备出发 */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      