import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`关于`}</h2>
    <p>{`我们致力于在50:1模型层面实现一些前沿的火箭和推进技术 - Scale Model Rocketry，包括但不限于推力矢量控制(TVC)、垂直起降(VTVL)、姿态控制(Attitude Control)、推进着陆(Propulsive Landing)、舰载计算机系统、实时遥测等。`}</p>
    <blockquote>
      <p parentName="blockquote">{`星辰大海，我们准备出发`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      