import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`联系`}</h2>
    <p><a parentName="p" {...{
        "href": "mailto:rpsl@rongyi.io"
      }}>{`RPSL@rongyi.io`}</a>{`: RPSL邮件列表，欢迎参与讨论，共同进步`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:hr@rongyi.io?subject=%5BRPSL%5D%E7%94%B3%E8%AF%B7%E5%8A%A0%E5%85%A5"
      }}>{`HR@rongyi.io`}</a>{`: 加入RPSL`}</p>
    <hr />
    <p><em parentName="p">{`一群有智慧有远见的人，在一起做一件有价值有意义的事`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      